import React from 'react';
import FrontDeskJPG from '@assets/images/front-desk.jpg';
import FrontDeskMobileJPG from '@assets/images/front-desk-mobile.jpg';
// eslint-disable-next-line import/no-cycle
import Contact from '~/components/atoms/Contact/Contact';
import HeaderFooterLayout from '~/components/molecules/HeaderFooterLayout/HeaderFooterLayout';

const ContactPage: React.FC = () => {
  return (
    <>
      <HeaderFooterLayout
        desktopHero={FrontDeskJPG}
        mobileHero={FrontDeskMobileJPG}
        altHero="Empfang Sportraum Arabellapark"
        filter={true}
      >
        <Contact />
      </HeaderFooterLayout>
    </>
  );
};

export default ContactPage;
