/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import styled from '@emotion/styled';
import SabineJPG from '@assets/images/sabine.jpeg';
import SchledererJPG from '@assets/images/schlederer-res.jpg';
import TrainingJPG from '@assets/images/training.jpeg';
import ErnährungPNG from '@assets/images/ernährung.png';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Header from '~/components/molecules/Header/Header';
import FrontDeskImage from '~/components/organisms/FrontDeskImage/FrontDeskImage';
import Teaser from '~/components/atoms/Teaser/Teaser';
import Duo from '~/components/molecules/Duo/Duo';
import Image from '~/components/atoms/Image/Image';
import TrainingImage from '~/components/molecules/TrainingImage/TrainingImage';
import Contact from '~/components/atoms/Contact/Contact';
import Footer from '~/components/molecules/Footer/Footer';
import MobileCTAHeader from '~/components/molecules/MobileCTAHeader/MobileCTAHeader';
import MobileCTAFooter from '~/components/molecules/MobileCTAFooter/MobileCTAFooter';
import { ImageContainerHome } from '~/utils/styles/utils';
import { useTranslationContext } from '~/pages/index';

const HomePage: React.FC = () => {
  const [menu, setMenu] = useState(false);
  const t = useTranslationContext();
  const { i18n } = useTranslation();

  const clickHandler = () => {
    setMenu(!menu);
  };

  const menuHandler = () => {
    if (menu) {
      setMenu(false);
    }
  };

  return (
    <>
      <Container>
        <MobileCTAHeader />
        <Header clickHandle={clickHandler} />
        <FrontDeskImage shaded menu={menu} clickHandle={menuHandler} />
        <Teaser
          heading={t('TeaserHeading')}
          text={t('TeaserText')}
          whiteBackground
          small={false}
          link={i18n.language === 'de' ? '/trainingsbereich/' : '/en/practice/'}
          headingLevel={1}
        />
        <Duo>
          <ImageContainerHome>
            <Image src={SabineJPG} alt="Sabine" width={600} />
          </ImageContainerHome>
          <ImageContainerHome>
            <Image src={SchledererJPG} alt="Schlederer" width={600} />
          </ImageContainerHome>
        </Duo>
        <Teaser
          heading={t('SportsMedicineHeading')}
          text={t('SportsMedicineText')}
          small={false}
          link={i18n.language === 'de' ? '/personal-training/' : '/en/neurology/'}
          headingLevel={2}
          height="350px"
        />
        <Duo>
          <ImageContainerHome>
            <Image src={ErnährungPNG} alt="Nutritional Counseling" width={600} />
          </ImageContainerHome>
          <ImageContainerHome>
            <Teaser
              heading={t('NutritionHeading')}
              text={t('NutritionText')}
              small
              whiteBackground
              link={
                i18n.language === 'de'
                  ? '/personal-training/'
                  : '/en/neuropsychology/'
              }
              headingLevel={3}
            />
          </ImageContainerHome>
        </Duo>
        <Duo>
          <ImageContainerHome>
            <Teaser
              heading={t('PTHeading')}
              text={t('PTText')}
              small
              whiteBackground={false}
              link={
                i18n.language === 'de'
                  ? '/personal-training/'
                  : '/en/sports-medicine-lactatetest/'
              }
              headingLevel={3}
            />
          </ImageContainerHome>
          <ImageContainerHome>
            <Image src={TrainingJPG} alt="Trainingsraum" width={600} />
          </ImageContainerHome>
        </Duo>
        <TrainingImage />
        <Contact />
        <Footer />
        <MobileCTAFooter />
      </Container>
    </>
  );
};

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 1200px) {
    width: 1250px;
  }
`;

const Heading = styled.h2`
  @media (min-width: 1200px) {
    max-width: 800px;
  }

  margin-left: auto;
  margin-right: auto;
  font-weight: normal;
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: center;
  color: black;
  font-size: 1.5rem;
  line-height: 1.5;
`;

export default HomePage;
