/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import FrontTrainingJPG from '@assets/images/front-training.jpg';
import FrontDeskMobileJPG from '@assets/images/front-training-mobile.jpg';
import Image from '~/components/atoms/Image/Image';
import GreenBox from '~/components/atoms/GreenBox/GreenBox';
import CovidBox from '~/components/atoms/CovidBox/CovidBox';
import Menu from '~/components/atoms/Menu/Menu';
import { useMobile } from '~/utils/hook-functions';
// eslint-disable-next-line import/no-cycle
import { useTranslationContext } from '~/pages/index';

type clickHandle = {
  (): void;
};

type Props = { shaded?: boolean; menu: boolean; clickHandle: clickHandle };

const FrontDeskImage: React.FC<Props> = ({ shaded, menu, clickHandle }) => {
  const [covid, setCovid] = useState(true);
  const mobile = useMobile();
  const t = useTranslationContext();

  const handleOutsideClick = () => {
    clickHandle();
  };

  const handleMenuClick = (event: MouseEvent) => {
    event.stopPropagation();
  };

  useEffect(() => {
    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  });

  useEffect(() => {
    const covidInterval = setInterval(() => {
      setCovid(!covid);
    }, 10000);

    return () => {
      clearInterval(covidInterval);
    };
  });

  const viewMenu = () => {
    if (mobile) {
      if (menu) {
        return (
          <MenuContainer>
            <Menu white={false} />
          </MenuContainer>
        );
      }
    }

    if (menu) {
      return (
        <div>
          <MenuContainer>
            <Menu white />
          </MenuContainer>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      {shaded ? (
        <Container>
          <Image
            src={FrontTrainingJPG}
            alt="Training bei Sportraum Arabellapark."
            srcmobile={FrontDeskMobileJPG}
          />
          {menu && !mobile ? <Filter /> : null}
          <Filter2 onClick={handleMenuClick} />
          <WelcomeContainer>
            {covid && !mobile && !menu ? (
              <div>
                <GreenBox
                  heading={t('GreenboxHeading')}
                  text={t('GreenboxText')}
                  h1
                  offpage
                />
                <CovidBox
                  heading={t('CovidboxHeading')}
                  text={t('CovidboxText')}
                />
              </div>
            ) : !menu ? (
              <GreenBox
                heading={t('GreenboxHeading')}
                text={t('GreenboxText')}
                h1
                offpage={false}
              />
            ) : (
              ''
            )}
          </WelcomeContainer>
          {viewMenu()}
        </Container>
      ) : (
        <Image
          src={FrontDeskJPG}
          alt="Empfang bei Sportraum Arabellapark mit Rosen."
        />
      )}
    </>
  );
};

const Container = styled.div`
  position: relative;
  max-width: 1200px;
  height: auto;
`;

const Filter = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 50%;
  background: rgba(0, 0, 0, 0.6);
`;

const Filter2 = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
`;

const WelcomeContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const MenuContainer = styled.div`
  position: absolute;
  top: 32px;
  left: 8rem;

  @media (min-width: 800px) {
    top: 10px;
  }
`;

export default FrontDeskImage;
