/* eslint-disable import/no-cycle */
/* eslint-disable react/no-danger */
/* eslint-disable no-secrets/no-secrets */
import React from 'react';
import styled from '@emotion/styled';
import { useTranslationContext } from '~/pages/kontakt';
import { useTranslationContext as useTranslationContextEn } from '~/pages/en/contact';
import { useTranslationContext as useTranslationContextIndex } from '~/pages/index';

const Contact: React.FC = () => {
  const t = useTranslationContext();
  const tEn = useTranslationContextEn();
  const t2 = useTranslationContextIndex();

  return (
    <>
      <Container>
        <SmallContainer>
          <h4 style={{ fontWeight: 'normal' }}>
            {t('ContactHeading') ||
              t2('ContactHeading') ||
              tEn('ContactHeading')}
          </h4>
          <p>
            {t2('ContactSportroom') || t('ContactSportroom') || tEn('ContactSportroom')}
          </p>
          <p>
            {t2('ContactStreet') || t('ContactStreet') || tEn('ContactStreet')}
          </p>
          <p>
            <a
              href="https://www.google.com/maps/place/Neurozentrum+Arabellapark/@48.1519591,11.6171166,17z/data=!4m8!1m2!2m1!1sneurozentrum+arabellapark!3m4!1s0x479e753848617349:0x2eb4f39f195be657!8m2!3d48.1519591!4d11.6193053"
              target="_blank"
              rel="noreferrer"
            >
              &gt; Google Maps
            </a>
          </p>
          <p css={{ marginTop: '1rem' }}>
            {t('ContactPhoneNeuro') ||
              t2('ContactPhoneNeuro') ||
              tEn('ContactPhoneNeuro')}
          </p>
          <p>+49 (0)89 95006764 (Fax)</p>
          <p>
            <a href="mailto:info@neurozentrum-arabellapark.de">
              info@neurozentrum-arabellapark.de
            </a>{' '}
          </p>
          <p>www.sportraum-arabellapark.de</p>
          <br />
          <div
            dangerouslySetInnerHTML={{
              __html:
                t('ContactTime') || t2('ContactTime') || tEn('ContactTime'),
            }}
          />
          <br />
          <a
            href="https://efa.mvv-muenchen.de/index.html?name_origin=&name_destination=streetID%3A1500001463%3A18%3A9162000%3A1%3ARosenkavalierplatz%3AM%C3%BCnchen%3ARosenkavalierplatz%3A%3ARosenkavalierplatz%3A81925%3AANY%3ADIVA_SINGLEHOUSE%3A1293440%3A5867829%3AMRCV%3ABAY%3A0&itdTripDateTimeDepArr=departure"
            target="_blank"
            rel="noreferrer"
          >
            {t('ContactPublicTransport') ||
              t2('ContactPublicTransport') ||
              tEn('ContactPublicTransport')}
          </a>
          <MarginTop/>
        </SmallContainer>
      </Container>
    </>
  );
};

const Container = styled.div`
  position: relative;
  background-color: white;
  margin-top: 1rem;

  @media (min-width: 1200px) {
    margin-top: 0;
    max-width: 1200px;
    width: 1200px;
    height: 650px;
  }
`;

const SmallContainer = styled.div`
  @media (min-width: 1200px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 500px;
    margin-bottom: 0 !important;
  }

  margin: auto;
  vertical-align: center;
  text-align: center;
  color: black;
  font-size: var(--primary-font-size);
  line-height: 1.5;

  h4 {
    font-size: 1.5rem;
  }

  margin-bottom: 2rem;
`;

const MarginTop = styled.div`
  @media (min-width: 1200px) {
    margin-top: 0;
  }
  margin-top: 2rem;
`;

export default Contact;
