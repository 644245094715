import React from 'react';
import styled from '@emotion/styled';

type Props = { heading: string; text: string; h1?: boolean; offpage?: boolean };

const GreenBox: React.FC<Props> = ({ heading, text, h1, offpage }) => {
  let box;
  if (!h1) {
    box = (
      <Container>
        {heading}
        <div>{text}</div>
      </Container>
    );
  } else if (offpage) {
    box = (
      <ContainerOffpage>
        {heading}
        <div>{text}</div>
      </ContainerOffpage>
    );
  } else {
    box = (
      <ContainerH1>
        {heading}
        <div>{text}</div>
      </ContainerH1>
    );
  }
  return <>{box}</>;
};

const ContainerH1 = styled.div`
  width: 15.625rem;
  color: white;
  text-align: center;
  font-size: 1.5rem;
  border: 2px solid var(--primary-color);
  padding: 1rem;

  div {
    font-size: 0.725rem;
  }
`;

const ContainerOffpage = styled.div`
  position: absolute;
  left: -9999px;
  top: -9999px;
`;

const Container = styled.div`
  width: 15.625rem;
  color: white;
  background-color: var(--primary-color);
  text-align: center;
  font-size: 1.5rem;
  border: 2px solid var(--primary-color);
  padding: 1rem;

  div {
    font-size: var(--primary-font-size);
  }
`;

export default GreenBox;
